<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row align-items-center">
            <CHeaderNav>
              <CHeaderNavItem class="px-3">
                <button
                  class="c-header-nav-btn"
                  @click="openSidebarEvent('orderChangeFilter')"
                >
                  <CIcon
                    v-if="!loading"
                    size="lg"
                    name="cil-filter"
                    class="mr-2"
                  />
                </button>
              </CHeaderNavItem>
            </CHeaderNav>
            <div>
              <CDropdown toggler-text="Detay" color="dark" class="m-1">
                <div role="group">
                  <CDropdownItem
                    :disabled="selectedOrders.length === 0"
                    @click="orderDetail"
                  >
                    Sipariş Detay
                  </CDropdownItem>
                  <CDropdownItem
                    :disabled="selectedOrders.length === 0"
                    @click="changeDetail"
                  >
                    Değişim Detay
                  </CDropdownItem>
                  <CDropdownItem
                    @click="openModalEvent('cancel', 'confirm', selectedOrders[0], 'ID:'+selectedOrders[0].id, 'Bu değişiğim talebi iptal edilecek. Onaylıyor musun?' )"
                    :disabled=" selectedOrders.length === 0 || 
                      (selectedOrders[0].orderChangeStatusType !== undefined && selectedOrders[0].orderChangeStatusType !== 'requested')
                    "
                  >
                    İptal Et
                  </CDropdownItem>
                </div>
              </CDropdown>
            </div>
            <div>
              <CDropdown toggler-text="Fatura" color="warning" class="m-1">
                <div role="group">
                  <CDropdownItem
                    @click="printPackingSlipNew"
                    :disabled=" selectedOrders.length === 0 ||
                      selectedOrders[0].orderChangeStatusType === 'requested' ||
                      selectedOrders[0].orderChangeStatusType === 'canceled'"
                    >
                    Fatura Yazdır
                  </CDropdownItem>
                  <CDropdownItem
                    @click="printShipAddress"
                    :disabled=" selectedOrders.length !== 1"
                  >
                    Kargo Adresi Bas
                  </CDropdownItem>
                </div>
              </CDropdown>
            </div>
           
            <div>
              <CDropdown toggler-text="Sonlandr." color="dark" class="m-1">
                <div role="group">
                  <CDropdownItem @click="setFilterParams({orderChangeStatusType: [{ label: 'shipped', value: 'shipped' }], checkedByUser: 'false', invoicePrintedFlag: 'true'})">
                    Gün Sonu: Kontrol Edilmeyen Sipariş Değişimleri
                  </CDropdownItem>
                  <CDropdownItem @click="setFilterParams({orderChangeStatusType: [{ label: 'committed', value: 'committed' }], invoicePrintedFlag: 'false'})">
                    Gün Sonu: Faturası Yazdırılmayan Sipariş Değişimleri
                  </CDropdownItem>
                </div>
              </CDropdown>
            </div>
            <div>
              <CButton
                :disabled="selectedOrders.length !== 1"
                block
                pressed
                color="dark"
                aria-pressed="true"
                @click="userNoteSearch"
              >
                Not
              </CButton>
            </div>
          </div>
        </CCardHeader>

        <CCardBody>
          <CDataTable
            :items="orders"
            :fields="fields"
            :loading="loading"
            :items-per-page="10"
            pagination
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="changeDetail"
            v-on:refresh="filterOrderChanges(filterParams)"
            columnFilter
            sorter
          >
            <template #select-header>
                <th style="vertical-align: middle; overflow: hidden; font-size: 12px;"> {{selectedOrders !== null ? selectedOrders.length : 0}}_Adet</th>
            </template>

            <template #select-filter="{item}">
              <div style="    display: flex; justify-content: center;">
                <CInputCheckbox
                  :checked="allSelect"
                  @update:checked="() => check(item, -1)"
                  custom
                />
              </div>
                
            </template>

            <template #select="{item,index}">
              <td style="display: flex; justify-content: center;">
                <CInputCheckbox
                  :checked="item._selected"
                  @update:checked="() => check(item, index)"
                  custom
                />
              </td>
            </template>
            <template #id-filter>
                <CInput
                  col="12"
                  style="margin-bottom: 0;"
                  :value.sync="filterParams.id"
                  v-on:keyup.enter="setFilterParams({id: filterParams.id}, true)"
                />
            </template>
            <template #orderId-filter>
                <CInput
                  col="12"
                  style="margin-bottom: 0;"
                  :value.sync="filterParams.orderId"
                  v-on:keyup.enter="setFilterParams({orderId: filterParams.orderId}, true)"
                />
            </template>
            <template #createTime="{item}">
              <td>
                {{ dateFormat(item.createTime) }}
              </td>
            </template>

            <template #customerFirstName-filter>
                <CInput
                  col="12"
                  style="margin-bottom: 0;"
                  :value.sync="filterParams.customerFirstName"
                  v-on:keyup.enter="setFilterParams({customerFirstName: filterParams.customerFirstName}, true)"
                />
            </template>

            <template #customerLastName-filter>
                <CInput
                  col="12"
                  style="margin-bottom: 0;"
                  :value.sync="filterParams.customerLastName"
                  v-on:keyup.enter="setFilterParams({customerLastName: filterParams.customerLastName}, true)"
                />
            </template>

            <template #deliveredPerson-filter>
                <CInput
                  col="12"
                  style="margin-bottom: 0;"
                  :value.sync="filterParams.deliveredPerson"
                  v-on:keyup.enter="setFilterParams({deliveredPerson: filterParams.deliveredPerson}, true)"
                />
            </template>
            
            <template #orderChangeStatusType-filter>
                <Multiselect
                    placeholder=""
                    horizontal
                    select-label="Seçiniz"
                    selected-label="Seçildi"
                    deselect-label="Kaldır"
                    size="sm"
                    v-model="filterParams.orderChangeStatusType"
                    :options="orderChangeStatusType"
                    :multiple="true"
                    label="label"
                    track-by="label"
                    @input="() => setFilterParams({orderChangeStatusType: filterParams.orderChangeStatusType}, true)"
                />
            </template>
            <template #orderChangeStatusType="{item}">
              <td>
                <CBadge :color="colors[(orderChangeStatusType.findIndex(type => type.value == item.orderChangeStatusType) - 1 )% colors.length]">
                  {{ item.orderChangeStatusType }}
                </CBadge>
              </td>
            </template>
            <template #paymentType="{item}">
              <td>
                <CBadge v-if="paymentTypes[item.paymentType - 1]" :color="colors[ item.paymentType % colors.length]">
                  {{ paymentTypes[item.paymentType - 1] }}
                </CBadge>
              </td>
            </template>
            <template #customRefundFlag="{item}">
              <td>
                <CIcon
                  v-if="item.customRefundFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #isNoteAdded="{item}">
              <td>
                <CIcon
                  v-if="item.isNoteAdded"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon v-else name="cil-x" class="red" />
              </td>
            </template>
            <template #adminApprovedFlag="{item}">
              <td @mouseover="getTooltipMessage(item, 'adminApprovedFlag')">
                <CIcon
                  v-c-tooltip.mouseenter="{ content: adminApprovedFlagMessage }"
                  v-if="item.adminApprovedFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon
                  v-c-tooltip.mouseenter="{ content: adminApprovedFlagMessage }"
                  v-show="!item.adminApprovedFlag"
                  name="cil-x"
                  class="red"
                />
              </td>
            </template>
            <template #invoicePrintedFlag="{item}">
              <td @mouseover="getTooltipMessage(item, 'invoicePrintedFlag')">
                <CIcon
                  v-c-tooltip.mouseenter="{
                    content: invoicePrintedFlagMessage
                  }"
                  v-if="item.invoicePrintedFlag"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon
                  v-show="!item.invoicePrintedFlag"
                  name="cil-x"
                  class="red"
                />
              </td>
            </template>
            <template #checkedByUser="{item}">
              <td @mouseover="getTooltipMessage(item, 'checkedByUser')">
                <CIcon
                  v-c-tooltip.mouseenter="{ content: checkedUserMessage }"
                  v-if="item.checkedByUser"
                  class="green"
                  name="cil-check-alt"
                >
                </CIcon>
                <CIcon v-show="!item.checkedByUser" name="cil-x" class="red" />
              </td>
            </template>
            <template #informShippingCompanyTime="{item}">
              <td
                @mouseover="
                  getTooltipMessage(item, 'informShippingCompanyTime')
                "
              >
                <CIcon
                  v-c-tooltip.mouseenter="{
                    content: informShippingCompanyTimeMessage
                  }"
                  v-if="item.informShippingCompanyTime"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon
                  v-else
                  name="cil-x"
                  class="red"
                />
              </td>
            </template>
            <template #trackingInformTime="{item}">
              <td @mouseover="getTooltipMessage(item, 'trackingInformTime')">
                <CIcon
                  v-c-tooltip.mouseenter="{
                    content: trackingInformTimeMessage
                  }"
                  v-if="item.trackingInformTime"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon
                  v-show="!item.trackingInformTime"
                  name="cil-x"
                  class="red"
                />
              </td>
            </template>
            <template #deliveryInformTime="{item}">
              <td @mouseover="getTooltipMessage(item, 'deliveryInformTime')">
                <CIcon
                  v-c-tooltip.mouseenter="{
                    content: deliveryInformTimeMessage
                  }"
                  v-if="item.deliveryInformTime"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon
                  v-show="!item.deliveryInformTime"
                  name="cil-x"
                  class="red"
                />
              </td>
            </template>
            <template #note="{item}">
              <td @mouseover="getTooltipMessage(item, 'note')">
                <p
                  v-c-tooltip.mouseenter="{ content: noteMessage, placement }"
                  style="width: 70px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;"
                >
                  {{ item.note }}
                </p>
              </td>
            </template>
            <template #confirmedTime="{item}">
              <td @mouseover="getTooltipMessage(item, 'confirmedByUser')">
                <CIcon
                  v-c-tooltip.mouseenter="{ content: confirmedUserMessage }"
                  v-if="item.confirmedByUser"
                  class="green"
                  name="cil-check-alt"
                />
                <CIcon
                  v-show="!item.confirmedByUser"
                  name="cil-x"
                  class="red"
                />
              </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="operationEvent"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      :size="confirmModalSize"
      ref="confirm"
      :isFrame="isFrame"
      :noFooter="noFooter"
    />
    <ListModal
      v-else-if="actionType == 'list'" 
      :size="modalSize"
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :module="module"
      :form="form"
      :data="modalProps"
      :additionalButtons = "additionalButtons"
      :additionalEvent = "additionalEvent"
      ref="list"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="operationEvent"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterOrderChanges"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>
import moment from 'moment'
import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import ListModal from '../components/listModal'
import { prepareFilterParams } from "@/helpers/custom"
import Multiselect from 'vue-multiselect'

export default {
  name: 'OrderChangeReserved',
  components: {
   FormModal, ConfirmModal, FilterSidebar, ListModal, Multiselect
  },
  data() {
    return {
      allSelect: false,
      isFrame: false,
      confirmModalSize: 'sm',
      modalSize: 'lg',
      colors: [ 'success',  'info', 'primary',  'warning', 'danger', 'dark', 'secondary'],
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'orderUpdatePayment',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openSidebar: false,
      filterForm: 'orderFilter',
      filterParams: {},
      lastItem: {},
      noFooter: false,
      additionalButtons: [],
      fields: [
        {
          key: 'select',
          label: '',
          _style: 'min-width:1%',
          filter: true
        },
        { key: 'id', label: 'ID', _style: 'font-size:12px', filter: true },
        { key: 'createTime', label: 'Oluş._Tarihi', _style: 'font-size:12px', filter: false },
        { key: 'orderId', label: 'Sipariş_Id', _style: 'font-size:12px', filter: true },
        {
          key: 'customerFirstName',
          label: 'Müşteri_Adı',
          _style: 'font-size:12px',
          filter: true
        },
        {
          key: 'customerLastName',
          label: 'Müşteri_Soyadı',
          _style: 'font-size:12px',
          filter: true
        },
        {
          key: 'deliveredPerson',
          label: 'Alıcı_İsmi',
          _style: 'font-size:12px',
          filter: true
        },
        {
          key: 'orderChangeStatusType',
          label: 'Değişim_Durum',
          _style: 'font-size:12px',
          filter: true
        },
        {
          key: 'changeCount',
          label: 'Değişim_Sayısı',
          _style: 'font-size:12px',
          filter: false
        },
        { key: 'note', label: 'Not', _style: 'font-size:12px', filter: false },
        {
          key: 'paymentType',
          label: 'İade_Hesabı',
          _style: 'font-size:12px; color: red',
          filter: false
        },
        { key: 'refundAmount', label: 'İade_Tutarı', _style: 'font-size:12px', filter: false },
        {
          key: 'customRefundFlag',
          label: '*İade_Tutarı_Farklı',
          _style: 'font-size:12px; color: red',
          filter: false
        },
        { key: 'isNoteAdded', label: 'Op.Notu', _style: 'font-size:12px', filter: false },
        {
          key: 'adminApprovedFlag',
          label: 'İade_Eden',
          _style: 'font-size:12px',
          filter: false
        },
        {
          key: 'invoicePrintedFlag',
          label: 'Fatura',
          _style: 'font-size:12px',
          filter: false
        },
        { key: 'checkedByUser', label: 'Kontrol', _style: 'font-size:12px', filter: false },
        { key: 'confirmedTime', label: 'Onay', _style: 'font-size:12px', filter: false },
        {
          key: 'informShippingCompanyTime',
          label: 'Bilgilendirme',
          _style: 'font-size:12px',
          filter: false
        },
        { key: 'trackingNumber', label: 'Takip_No', _style: 'font-size:12px', filter: false },
        {
          key: 'trackingInformTime',
          label: 'Taşıma_Durumunda',
          _style: 'font-size:12px',
          filter: false
        },
        {
          key: 'deliveryInformTime',
          label: 'Teslim_Edildi',
          _style: 'font-size:12px',
          filter: false
        }
      ],
      
      orderChangeStatusType: [
        { label: "Seçiniz", value: undefined},
        { label: 'requested', value: 'requested' },
        { label: 'committed', value: 'committed' },
        { label: 'shipped', value: 'shipped' },
        { label: 'tracking', value: 'tracking' },
        { label: 'delivered', value: 'delivered' },
        { label: 'canceled', value: 'canceled' }
      ],
      selectedOrders: [],
      paymentType: '',
      paymentTypes: ['PayPalEC', 'EFT', 'CreditCard', 'FreePromo', 'DebitCard', 'IyzicoPay'],
      adminApprovedFlagMessage: '',
      invoicePrintedFlagMessage: '',
      checkedUserMessage: '',
      confirmedUserMessage: '',
      informShippingCompanyTimeMessage: '',
      trackingInformTimeMessage: '',
      deliveryInformTimeMessage: '',
      noteMessage: '',
      placement: 'bottom',
      filters: [
        { type: 'numeric', dataIndex: 'id', field: 'id', comparison: 'eq' },
        { type: 'numeric', dataIndex: 'orderId', field: 'orderId', comparison: 'eq' },
        { type: 'boolean', dataIndex: 'customRefundFlag', field: 'customRefundFlag'},
        { type: 'string', dataIndex: 'customerFirstName', field: 'customerFirstName'},
        { type: 'string', dataIndex: 'customerLastName', field: 'customerLastName'},
        { type: 'string', dataIndex: 'deliveredPerson', field: 'deliveredPerson'},
        { type: 'listString', dataIndex: 'orderChangeStatusType', field: 'orderChangeStatusType'},
        { type: 'numeric', dataIndex: 'changeCount', field: 'changeCount', comparison:'eq'},
        { type: 'string', dataIndex: 'note', field: 'note'},
        { type: 'string', dataIndex: 'promoCode', field: 'promoCode'},
        { type: 'string', dataIndex: 'groupName', field: 'groupName'},
        { type: 'string', dataIndex: 'discountCart', field: 'discountCart'},
        { type: 'numeric', dataIndex: 'paymentType', field: 'paymentMethod', comparison:'eq'},
        { type: 'string', dataIndex: 'refundAmount', field: 'refundAmount', comparison: 'eq'},
        { type: 'boolean', dataIndex: 'adminApprovedFlag', field: 'adminApprovedFlag'},
        { type: 'boolean', dataIndex: 'invoicePrintedFlag', field: 'invoicePrintedFlag'},
        { type: 'boolean', dataIndex: 'informShippingCompanyTime', field: 'informShippingCompanyTime' },
        { type: 'string', dataIndex: 'trackingNumber', field: 'trackingNumber'},
        { type: 'boolean', dataIndex: 'checkedByUser', field: 'checkedByUser'},
        { type: 'date', dataIndex: 'startTime', field: 'createTime', comparison: 'gt'},
        { type: 'date', dataIndex: 'endTime', field: 'createTime', comparison: 'lt'},
      ],
      momentFormat: moment
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },
  computed: {
    orders() {
      return this.$store.getters.orderChanges 
    },
    loading() {
      return this.$store.getters.orderLoading
    },
    reloadParams() {
      return [this.activePage]
    }
  },
  methods: {
    checkData(data, keys, val){
      let toast = {}
      let status = true;

      if(keys.includes('length')){
        if(data.length > val){
          toast.msg = 'Lütfen, En fazla '+val+' satır seçimi yapın!'
          toast.header = 'Uyarı'
          toast.color = 'warning'
          status = false
        }
      } 

      Object.keys(toast).length > 0 && vm.$store.commit('showToast', toast)
      return status
    },
    async openModalEvent(actionType, form, data, title, desc){
      this.confirmModalSize = 'sm'
      this.modalSize = 'lg'
      this.isFrame = false
      this.additionalButtons = []
      this.noFooter = false
      if(actionType == 'print'){
        this.confirmModalSize = 'xl'
        this.isFrame = true
      }else if(actionType == 'list'){
        this.modalSize = 'xl'
        if(form == 'orderChangeDetailList' && this.selectedOrders[0].orderChangeStatusType == 'requested'){
          this.additionalButtons = [
            {name: 'Değişim Talebini Onayla', color: 'success', action: 'confirm'}
          ]
        }
      }
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },

    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    dateFormat(date){
      return moment(date).format('DD.MM.YYYY HH:mm:ss')
    },

    async operationEvent(item, action){
      if(action == 'print'){
        this.$refs.confirm.$refs.desc.contentWindow.printPage();
        if(item){
          await this[item]()
        }
        if(this.openModal){
          await this.filterOrders(this.filterParams)
          this.openModalEvent()
          this.selectedOrders = []
        }
        return
      }else if(action == "cancel"){
        await this.$store.dispatch('orderChange_cancel', {orderChangeId: this.selectedOrders[0].id})
      }
      if(this.$store.getters.orderStatus.success){
        this.filterOrderChanges(this.filterParams)
        this.openModalEvent()
        this.selectedOrders = []
      }
    },
    async additionalEvent(item, action){
      if(action == 'confirm'){
        this.$refs.list.$refs.customComponent.additionalEvent()
      }else if(action == 'close'){
        this.filterOrderChanges(this.filterParams)
        this.openModalEvent()
        this.selectedOrders = []
      }
    },
    setFilterParams: function(params, combine){
      if(combine){
        this.filterParams = {...this.filterParams, ...params};
      }else
        this.filterParams = params
      this.filterOrderChanges(this.filterParams)
    },
    getTooltipMessage(item, type) {
      if (type === 'adminApprovedFlag') {
        this.adminApprovedFlagMessage = ''
        this.adminApprovedFlagMessage = item.adminApprovedFlag
          ? 'Değişim talebi KAFT ekibi tarafından oluşturulmuş'
          : 'Değişim talebi Müşteri tarafından oluşturulmuş!'
      } else if (type === 'invoicePrintedFlag') {
        this.invoicePrintedFlagMessage = ''
        this.invoicePrintedFlagMessage =
          'Fatura kesim tarihi: ' +
          this.momentFormat(item.invoicePrintTime).format('DD.MM.YYYY HH:mm')
      } else if (type === 'checkedByUser') {
        this.checkedUserMessage = ''
        if (item.checkedByUser !== '') {
          this.checkedUserMessage =
            'Kontrol eden: ' +
            item.checkedUserName +
            ' ' +
            this.momentFormat(item.checkedTime).format('DD.MM.YYYY HH:mm')
        }
      } else if (type === 'informShippingCompanyTime') {
        this.informShippingCompanyTimeMessage = ''
        this.informShippingCompanyTimeMessage =
          'Kargo Firması Bilgilendirme Tarihi: ' +
          this.momentFormat(item.informShippingCompanyTime).format(
            'DD.MM.YYYY HH:mm'
          )
      } else if (type === 'trackingInformTime') {
        this.trackingInformTimeMessage = ''
        this.trackingInformTimeMessage =
          'Kargo Taşıma Durumunda: ' +
          this.momentFormat(item.trackingInformTime).format('DD.MM.YYYY HH:mm')
      } else if (type === 'deliveryInformTime') {
        this.deliveryInformTimeMessage = ''
        this.deliveryInformTimeMessage =
          'Kargo Teslim Edildi: ' +
          this.momentFormat(item.deliveryInformTime).format('DD.MM.YYYY HH:mm')
      } else if (type === 'note') {
        this.noteMessage = ''
        this.noteMessage = item.note
      } else if (type === 'confirmedByUser') {
        this.confirmedUserMessage = ''
        if (item.confirmedByUser !== '') {
          this.confirmedUserMessage =
            'Onay veren: ' +
            item.confirmedUserName +
            ' ' +
            this.momentFormat(item.confirmedTime).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    rowClicked(item, index, column) {
      this.paymentType = item.paymentType
      if (column === 'note') {
        if (item.note !== '') {
          this.copyCustomerNote( window.getSelection().toString() !== '' ?  window.getSelection().toString() : item.note)
        }
      }
      if (column !== 'select') {
        this.selectedOrders = []
        this.selectedOrder = {}
        for (var i = 0; i < this.orders.length; i++) {
          this.$set(this.orders[i], '_selected', false);
          this.$set(this.orders[i], '_classes', '');

        }
        const val = Boolean(this.orders.find(e => e.id == item.id)._selected)
        this.$set(this.orders.find(e => e.id == item.id), '_selected', !val)
        this.$set(this.orders.find(e => e.id == item.id), '_classes', !val ? 'selected' : '');

        this.orders.map(u => {
          if (u._selected) {
            this.selectedOrders.push(u)
          }
        })
        this.selectedOrder = this.selectedOrders[0]
        this.allSelect = false
      } else {
        this.check(item, index)
      }
    },
    copyCustomerNote(text) {
      const element = document.createElement('textarea')
      element.value = text
      document.body.appendChild(element)
      element.focus()
      element.setSelectionRange(0, element.value.length)
      document.execCommand('copy')
      document.body.removeChild(element)
      let toast = {
          header:  'Müşteri Notu',
          color:  'success',
          msg:  'Kopyalama işlemi yapıldı'
      }
      vm.$store.commit('showToast', toast)
    },
    async check(item, index) {
      this.selectedOrder = {}
      let tmpSelected = []
      if(index > -1){
        const val = Boolean(this.orders[index]._selected)
        this.$set(this.orders[index], '_selected', !val)
        this.$set(this.orders[index], '_classes', !val ? 'selected' : '');

        await this.orders.map(async u => {
          if (u._selected) {
            await tmpSelected.push(u)
          }
        })
        this.selectedOrder = tmpSelected[0]
      }else{
        if(this.allSelect){
          await this.orders.map(async (u, i) => {
            await this.$set(this.orders[i], '_selected', false)
            this.$set(this.orders[i], '_classes', '');

          })
        }else{
          await this.orders.map(async (u, i) => {
            await this.$set(this.orders[i], '_selected', true)
            this.$set(this.orders[i], '_classes', !'selected');

            await tmpSelected.push(u)
          })
        }
      }
      this.selectedOrders = [...tmpSelected]
      this.allSelect = this.orders.every(o => o._selected === true)

    },
    
    async changeDetail(){
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return

      await this.$store.dispatch('order_changeDetail', {changeId: this.selectedOrders[0].id})
      await this.$store.dispatch('order_reservedTypes')
      this.openModalEvent('list', 'orderChangeDetailList', this.selectedOrders[0], this.selectedOrders[0].id + " ID'li Değişim Talebi" )
    },
    
    async userNoteSearch() {
       let formData = new FormData();
        formData.append('page', 1)
        formData.append('start', 0)
        formData.append('limit', 25)
        formData.append('sort', 'id')
        formData.append('dir', 'DESC')

        let params = {
            "noteOnModel": 'nc_order',
            "modelId": this.selectedOrders[0].orderId,
            formData: formData
        }
      await this.$store.dispatch('user_notes', params)
      this.openModalEvent('list', 'orderNoteList', this.selectedOrders[0], 'Not (Sipariş: ' + this.selectedOrders[0].id +')')

    },
    async informShippingCompany(force) {
      let orderIds = this.selectedOrders.map(({ orderId }) => orderId)
      let toast
      let params = { 
        "orderIds": orderIds.join(","),
        "forceInform": force
      }
      await this.$store.dispatch('order_informShippingCompany', params)
      if(this.$store.getters.orderStatus.success){
        toast = {
          msg: this.$store.getters.orderStatus.success + ' ID li sipariş bilgileri başarılı bir şekilde gönderilmiştir.',
          header: 'BAŞARILI',
          color: 'success'
        }
      }else{
        toast = {
          msg: 'HATALI: ' + this.$store.getters.orderStatus.error+' ID li sipariş bilgileri gönderilememiştir!\n HATA MESAJI: ' + this.$store.getters.orderStatus.errMsg,
          header: 'HATALI',
          color: 'danger'
        }
      }
      vm.$store.commit('showToast', toast)
      await this.filterOrderChanges(this.filterParams);
    },
    async confirmPrintPackingSlip() {
      let params = {
        orderIds: this.selectedOrders[0].orderId,
        invoiceIds: this.$refs.confirm.$refs.desc.contentWindow.getInvoiceIds(),
        orderChangeId: this.selectedOrders[0].id
      }
      await this.$store.dispatch('order_updateOrderAndInvoiceStatus', params)
      if(this.$store.getters.orderStatus.success){
        // DHL veya UPS ise bildir
        if ([4,6].includes(this.selectedOrders[0].shippingCompanyId)) {
          this.informShippingCompany(undefined)
        } 
        this.openModalEvent()
        await this.filterOrderChanges(this.filterParams);
      }
    },

    async printPackingSlipNew() {
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
      
      await this.$store.dispatch('orderChange_isThereRefund', {orderChangeId: this.selectedOrders[0].id})

      if(this.$store.getters.orderStatus.success){
        let params = { 
          orderId: this.selectedOrders[0].orderId,
          emailAddress: this.selectedOrders[0].customerEmail,
          orderChangeId: this.selectedOrders[0].id,
          isPackingSlip: true,
          isGiftInvoice: this.selectedOrders[0].invoiceAddressId ? true : false
        }

        await this.$store.dispatch('order_eInvoicePrint', params)

        await this.openModalEvent('print', 'confirm', 'confirmPrintPackingSlip', 'ID: ' + this.selectedOrders[0].id +' - Faturayı yazdırmak ister misin?')
        this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)

      }else{
        let toast = {
            header:  'HATA!',
            color:  'danger',
            msg:   'Bu değişimin fatura basılamaz. ETTN si henüz oluşmamış ya da sadece iade talebi.'
        }
        vm.$store.commit('showToast', toast)

      }
    },

    async printShipAddress() {
      if(!this.checkData(this.selectedOrders, ['length'], 10)) return

      let selected = this.selectedOrders.map(so =>  so.orderId +','+so.customerEmail)

      await this.$store.dispatch('order_shippingAddress', {paramStr: selected.join('|')})
      await this.openModalEvent('print', 'confirm', null, 'Kargo gönderi adreslerini yazdırmak ister misin?')

      this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderJSP)
    },
    
    async orderDetail() {
      if(!this.checkData(this.selectedOrders, ['length'], 1)) return
      await this.$store.dispatch('encryptemail',{ "orderId": this.selectedOrder.orderId, "email": this.selectedOrder.customerEmailAddress})
      if(this.$store.getters.encrytedEmail.success){
        await this.$store.dispatch('orderDetail',{ "orderId": this.selectedOrder.orderId, "encryEmail": this.$store.getters.encrytedEmail.encrytedEmail})
        await this.openModalEvent('print', 'confirm', this.selectedOrder, 'Sipariş Detayı: '+this.selectedOrder.orderId+' siparişi yazdırmak ister misin?')
        this.$store.commit('orderJSP',this.$store.getters.orderDetailCaller)
        this.$refs.confirm.$refs.desc.contentDocument.write(this.$store.getters.orderDetailCaller)
      }
    },

    async filterOrderChanges(params) {
      this.selectedOrders = []
      this.selectedOrder = {}
      this.allSelect = false;

      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)

      await this.$store.dispatch('orderChange_list', formData)

      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
      
    },
   
  },
  created() {
    this.filterOrderChanges(this.filterParams)
  }
}
</script>
